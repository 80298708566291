@charset "utf-8";
.m-checkbox {
  display: inline-block;
  @include fs(16);
  @include sp {
    @include vw(28);
  }
  input[type=checkbox] {
    display: none;
  }
  &__txt {
    cursor: pointer;
    display: inline-block;
    padding-left: 24px;
    position: relative;
    &:before {
      background: #fff;
      border: 1px solid $color_borderEnclosure;
      content: '';
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      top: 5px;
      width: 20px;
      .is-error & {
        background: $color_attentionBg;
      }
    }
    &:after {
      border-right: 2px solid $color_txt;
      border-bottom: 2px solid $color_txt;
      content: '';
      display: block;
      height: 20px;
      left: 9px;
      opacity: 0;
      position: absolute;
      top: -2px;
      transform: rotate(45deg);
      width: 9px;
    }
  }
}


input[type=checkbox]:checked + .m-checkbox__txt::after {
  opacity: 1;
}