@charset "utf-8";

// リンクボタン
//
// Styleguide 7.0

// 通常リンクボタン (Parts 7.1)
//
// PCの表示はリンクで、スマホの表示はボタン
//
// Markup:
// <a class="m-linkBtn" href="">Parts 7.1</a>
//
// Styleguide 7.1

.m-linkBtn {
  @include pc {
    display: inline-block;
    @include fs(14);
    padding-right: 16px;
    position: relative;
  }
  @include sp {
    align-items: center;
    background-color: #fff;
    border: 1px solid $color_txt;
    border-radius: 2px;
    color: $color_txt;
    display: inline-flex;
    @include vw(28);
    font-family: $font-family-primary;
    justify-content: center;
    line-height: 1;
    padding: 48/750*100vw 0;
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  &:after {
    @include pc {
      border-top: 2px solid $color_primary;
      border-right: 2px solid $color_primary;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 9px;
      width: 9px;
      transform: translateY(-50%) rotate(45deg);
      transition: transform .2s ease-in;
      position: absolute;
      top: 50%;
      right: 0;
    }
    @include sp {
      border-top: 1px solid $color_primary;
      border-right: 1px solid $color_primary;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 18/750*100vw;
      position: absolute;
      right: 32/750*100vw;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 18/750*100vw;
    }
  }
  &:hover {
    &:after {
      @include pc {
        transform: translate(8px, -50%) rotate(45deg);
      }
    }
  }
}