@charset "utf-8";

// ボックス
//
// Styleguide 13.0

// ボックス (Parts 13.1)
//
// Markup:
// <div class="m-box">
//   <div class="m-box__inner">
//     <p class="m-txt">Parts 13.1</p>
//   </div>
//   <div class="m-box__inner">
//     <p class="m-txt">Parts 13.1</p>
//   </div>
// </div>
//
// Styleguide 13.1

.m-box {
  border: solid 1px $color_border;
  border-radius: 4px;
  padding: 60px;
  width: 100%;
  @include sp {
    border-radius: 2px;
    padding: 60/750*100vw 40/750*100vw;
  }
  &:not(:first-child) {
    margin-top: 40px;
    @include sp {
      margin-top: 40/750*100vw;
    }
  }
  &__inner {
    &:not(:first-child) {
      border-top: solid 1px $color_border;
      margin-top: 60px;
      padding-top: 60px;
      @include sp {
        margin-top: 60/750*100vw;
        padding-top: 60/750*100vw;
      }
    }
  }
  .m-btn {
    @include pc {
      width: 100%;
    }
  }
  .m-btnOuter--narrow {
    @include pc {
      margin-left: -40px;
    }
    .m-btnOuter__item {
      @include pc {
        margin-left: 40px;
        width: calc((100% - 40px * 3 - .01px) / 3);
      }
    }
  }
}
