@charset "utf-8";
.m-anc {
  margin-top: -80px;
  padding-top: 80px;
  pointer-events: none;
  @include sp {
    margin-top: -100/750*100vw;
    padding-top: 100/750*100vw;
  }
}
