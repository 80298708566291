@charset "utf-8";

// 画像
//
// Styleguide 10.0

// 通常 (Parts 10.1)
//
// Markup:
// <figure class="m-img">
//   <img src="https://placehold.jp/400x300.png" alt="Parts 10.1">
//   <figcaption>Parts 10.1</figcaption>
// </figure>
//
// Styleguide 10.1

// 角丸なし (Parts 10.2)
//
// Markup:
// <figure class="m-img m-img--noRadius">
//   <img src="https://placehold.jp/400x300.png" alt="Parts 10.2">
//   <figcaption>Parts 10.2</figcaption>
// </figure>
//
// Styleguide 10.2

// 角丸なし (Parts 10.3)
//
// Markup:
// <figure class="m-img m-img--border">
//   <img src="https://placehold.jp/400x300.png" alt="Parts 10.3">
//   <figcaption>Parts 10.3</figcaption>
// </figure>
//
// Styleguide 10.3

.m-img {
  display: block;
  text-align: center;
  &:not(:first-child) {
    margin-top: 16px;
    @include sp {
      margin-top: 24/750*100vw;
    }
  }
  &.m-img--noRadius {
    img {
      border-radius: 0;
    }
  }
  &.m-img--border {
    img {
      border: 1px solid $color_border;
    }
  }
  picture {
    display: block;
  }
  img {
    border-radius: 4px;
    max-width: 100%;
    @include sp {
      border-radius: 2px;
    }
  }
  figcaption {
    margin-top: 4px;
    @include pc() {
      @include fs(14);
    }
    @include sp() {
      margin-top: 8/750*100vw;
      @include vw(22);
    }
  }
}