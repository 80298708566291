@charset "utf-8";

.l-col2 {
  @include pc {
    display: flex;
  }
  &__item {
    @include pc {
      width: calc((100% - 40px) / 2);
    }
    .l-col2--border & {
      border: 1px solid $color_border;
      border-radius: 4px;
      padding: 20px;
      max-width: 100%;
      @include sp {
        border-radius: 2px;
        padding: 40/750*100vw;
      }
    }
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
      }
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
}

.l-col3 {
  @include pc {
    display: flex;
  }
  &__item {
    @include pc {
      width: calc((100% - 40px) / 3);
    }
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
      }
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
}
