@charset "utf-8";

.m-partsList {
  display: flex;
  flex-wrap: wrap;
  &__item {
    @include pc {
      margin-left: 40px;
      width: calc((100% - 40px * 2 - .01px) / 3);
    }
    &:nth-child(3n + 1) {
      @include pc() {
        margin-left: 0;
      }
    }
    &:nth-child(n + 4) {
      @include pc {
        margin-top: 40px;
      }
    }
    &:not(:first-child) {
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
  &__img {
    margin-bottom: 10px;
    @include sp {
      margin-bottom: 20/750*100vw;
    }
    img {
      border-radius: 4px;
      @include sp {
        border-radius: 2px;
      }
    }
  }
  &__ttl {
    @include fs(18);
    line-height: 1.5;
    @include sp {
      @include vw(28);
    }
    &:not(:last-child) {
      margin-bottom: 10px;
      @include sp {
        margin-bottom: 10/750*100vw;
      }
    }
  }
  &__txt {
    @include fs(14);
    line-height: 1.5;
    @include sp {
      @include vw(22);
    }
  }
}

.m-PartsDetail {
  &__item {
    border-bottom: 1px solid $color_border;
    &:first-child {
      border-top: 1px solid $color_border;
    }
  }
  &__contents {
    display: none;
    padding: 0 0 30px 0;
    position: relative;
    @include sp {
      padding: 0 0 40/750*100vw 0;
    }
  }
  &__ttl {
    cursor: pointer;
    padding: 30px 80px 30px 0;
    position: relative;
    transition: color .2s ease-in;
    @include pc {
      display: flex;
    }
    @include sp {
      @include vw(28);
      padding: 40/750*100vw 70/750*100vw 40/750*100vw 0;
    }
    &:hover {
      @include pc {
        color: $color_primary;
      }
    }
    &:before {
      content: "";
      display: block;
      border-top: solid 2px $color_primary;
      position: absolute;
      right: 40px;
      transform: translateY(-50%);
      top: 50%;
      width: 20px;
      z-index: 2;
      @include sp {
        border-width: 1px;
        right: 40/750*100vw;
        width: 32/750*100vw;
      }
    }
    &:after {
      content: "";
      display: block;
      border-top: solid 2px $color_primary;
      right: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      transition: transform .2s ease-in;
      width: 20px;
      z-index: 2;
      @include sp {
        content: "";
        border-width: 1px;
        right: 40/750*100vw;
        width: 32/750*100vw;
      }
    }
    &.is-open {
      &:after {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
  &__ttlId {
    @include pc {
      flex: 0 0 auto;
      padding-right: 1em;
      width: 190px;
    }
  }
  &__ttlName {
    @include pc {
      flex: 0 0 auto;
      padding-right: 1em;
      width: 328px;
    }
  }
  &__ttlNote {
    @include pc {
      flex: 1 1 auto;
    }
  }
}