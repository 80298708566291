@charset "utf-8";
.m-formLabel {
  display: inline-block;
  @include fs(16);
  font-weight: 700;
  @include pc {
    width: 100%
  }
  @include sp {
    @include vw(30);
  }
}
