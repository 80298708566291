@charset "utf-8";

.m-form {
  @include pc {
    margin-top: -30px;
  }
  @include sp {
    margin: -30/750*100vw -40/750*100vw 0 -40/750*100vw;
  }
  &__item {
    @include pc {
      display: flex;
    }
    @include sp {
      padding: 40/750*100vw;
    }
    &:nth-child(2n) {
      background-color: $color_bg;
    }
  }
  &__heading {
    @include pc {
      flex: 0 0 auto;
      padding: 30px 0 30px 20px;
      width: 290px;
    }
    @include sp {
      margin-bottom: 20/750*100vw;
    }
  }
  &__contents {
    @include pc {
      flex: 1 1 auto;
      padding: 30px;
    }
  }
  &__ttl {
    font-weight: 700;
  }
}

.m-formList {
  &__item {
    &:not(:first-child) {
      margin-top: 16px;
      @include sp {
        margin-top: 24/750*100vw;
      }
    }
  }
}

.m-formParts {
  &--nowrap {
    white-space: nowrap;
  }
  &:not(:first-child) {
    margin-top: 10px;
    @include sp {
      margin-top: 20/750*100vw;
    }
  }
  &:not(:last-child) {
    margin-bottom: 10px;
    @include sp {
      margin-bottom: 20/750*100vw;
    }
  }
}