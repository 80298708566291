@charset "utf-8";

// レイアウト
//
// Styleguide 99.0

// ブロック
//
// Styleguide 99.1

// 通常 (Parts 99.1.1)
//
// Markup:
// <div class="l-block">Parts 99.1.1</div>
//
// Styleguide 99.1.1

// 背景あり (Parts 99.1.2)
//
// Markup:
// <div class="l-block l-block--bg">Parts 99.1.2</div>
//
// Styleguide 99.1.2

// 区切り線あり (Parts 99.1.3)
//
// Markup:
// <div class="l-block l-block--border">Parts 99.1.3</div>
//
// Styleguide 99.1.3

.l-block {
  &--bg {
    background-color: $color_bg;
  }
  &--border {
    border-top: 1px solid $color_border;
  }
  &--hidden {
    overflow: hidden;
  }
  &--breadcrumb {
    padding: 20px 20px 0 20px;
    @include sp() {
      padding: 30/750*100vw 40/750*100vw 0 40/750*100vw;
    }
  }
  &__inner {
    padding-bottom: 120px;
    padding-top: 80px;
    @include sp {
      padding-bottom: 100/750*100vw;
      padding-top: 96/750*100vw;
    }
    &--narrow {
      padding-bottom: 120px;
      padding-top: 60px;
      @include sp {
        padding-bottom: 100/750*100vw;
        padding-top: 60/750*100vw;
      }
    }
    &--same {
      padding-bottom: 100px;
      padding-top: 100px;
      @include sp {
        padding-bottom: 100/750*100vw;
        padding-top: 100/750*100vw;
      }
    }
  }
}
