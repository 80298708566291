@charset "utf-8";

.p-diySection {
  @include pc {
    position: relative;
  }
  &__btn {
    @include pc {
      position: absolute;
      right: 0;
      top: 90px;
    }
    @include sp {
      margin-top: 60/750*100vw;
    }
  }
}

.p-diyList {
  display: flex;
  flex-wrap: wrap;
  &__item {
    @include pc {
      margin-left: 40px;
      width: calc((100% - 40px * 2 - .01px) / 3);
    }
    &:nth-child(3n + 1) {
      @include pc() {
        margin-left: 0;
      }
    }
    &:nth-child(n + 4) {
      @include pc {
        margin-top: 40px;
      }
    }
    &:not(:first-child) {
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
  &__img {
    margin-bottom: 10px;
    @include sp {
      margin-bottom: 20/750*100vw;
    }
    img {
      border-radius: 4px;
      @include sp {
        border-radius: 2px;
      }
    }
  }
  &__ttl {
    @include fs(18);
    line-height: 1.5;
    font-weight: 700;
    @include sp {
      @include vw(34);
    }
    &:not(:last-child) {
      margin-bottom: 10px;
      @include sp {
        margin-bottom: 20/750*100vw;
      }
    }
  }
  &__txt {
    @include fs(14);
    line-height: 1.5;
    @include sp {
      @include vw(28);
    }
  }
}
