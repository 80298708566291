@charset "utf-8";

// KV
//
// Styleguide 1.0

// KV (Parts 1.1)
//
// プランTOPなどで使用。
//
// Markup:
// <div class="m-kv">
//   <div class="m-kv__bg">
//     <picture>
//       <source media="(max-width: 750px)" srcset="https://placehold.jp/750x300.png">
//       <img class="object-fit" src="https://placehold.jp/1366x300.png" alt="">
//     </picture>
//   </div>
//   <div class="m-kv__inner">
//     <div class="l-contents--large">
//       <div class="m-kv__img">
//         <img src="https://placehold.jp/70x74.png" alt="">
//       </div>
//       <h1 class="m-kv__ttl">Parts 1.1</h1>
//     </div>
//   </div>
// </div>
//
// Styleguide 1.1

.m-kv {
  position: relative;
  &__bg {
    img {
      width: 100%;
      @include pc {
        height: 400px;
        font-family: 'object-fit: cover';
        object-fit: cover;
      }
    }
  }
  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 1;
  }
  &__ttl {
    color: #fff;
    @include fs(36);
    font-weight: 700;
    line-height: 1.2;
    @include pc {
      margin-top: 6px;
    }
    @include sp {
      @include vw(50);
    }
  }
  &__img {
    img {
      width: 70px;
      @include sp {
        width: 70/750*100vw;
      }
    }
  }
}

