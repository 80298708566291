@charset "utf-8";

.l-formBtn {
  position: fixed;
  right: 0;
  top: 120px;
  z-index: 99;
  @include sp {
    top: 646/750*100vw 0;
  }
  &__btn {
    align-items: center;
    background-color: $color_primary;
    border: 1px solid $color_primary;
    border-radius: 4px 0 0 4px;
    color: #fff;
    display: inline-flex;
    @include fs(16);
    font-family: $font-family-primary;
    justify-content: center;
    line-height: 1.5;
    padding: 10px 0;
    width: 160px;
    position: relative;
    cursor: pointer;
    @include sp {
      border-radius: 2px 0 0 2px;
      @include vw(28);
      justify-content: flex-start;
      padding: 12/750*100vw 0 12/750*100vw 24/750*100vw;
      width: 250/750*100vw;
    }
    &:after {
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 9px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      transition: transform .2s ease-in;
      width: 9px;
      @include sp {
        border-width: 1px;
        height: 18/750*100vw;
        right: 20/750*100vw;
        width: 18/750*100vw;
      }
    }
    &:hover {
      color: #fff;
      &:after {
        @include pc {
          transform: translate(8px, -50%) rotate(45deg);
        }
      }
    }
  }
}