@charset "utf-8";
.u{
  &-mt00{
    margin-top: 0;
  }
  &-mb00{
    margin-bottom: 0;
  }
  &-pci{
    display:inline-block;
    @include sp{
      display:none;
    }
  }
  &-spi{
    display:none;
    @include sp{
      display:inline-block;
    }
  }
  &-pc{
    display:block;
    @include sp{
      display:none;
    }
  }
  &-sp{
    display:none;
    @include sp{
      display:block;
    }
  }
  &-taC {
    text-align: center;
  }
  &-taL {
    text-align: left;
  }
  &-taR {
    text-align: right;
  }
  &-pcTaC {
    @include pc{
      text-align: center;
    }
  }
  &-pcTaL {
    @include pc{
      text-align: left;
    }
  }
  &-pcTaR {
    @include pc{
      text-align: right;
    }
  }
  &-spTaC {
    @include sp{
      text-align: center;
    }
  }
  &-spTaL {
    @include sp{
      text-align: left;
    }
  }
  &-spTaR {
    @include sp{
      text-align: right;
    }
  }
  &-ls0 {
    letter-spacing: 0;
  }
  &-pcLs0 {
    @include pc{
      letter-spacing: 0;
    }
  }
  &-spLs0 {
    @include sp{
      letter-spacing: 0;
    }
  }
  &-fcPrimary {
    color: $color_primary;
  }
  &-flex {
    display: flex;
    justify-content: space-between;
  }
  &-wsN{
    white-space: nowrap;
  }
}
