@charset "utf-8";

// スライダー
//
// Styleguide 11.0

// スライダー (Parts 11.1)
//
// Markup:
// <div class="m-sliderOuter">
//   <div class="m-sliderOuter__slider">
//     <div class="m-slider swiper-container js-slider01">
//       <div class="swiper-wrapper">
//         <div class="swiper-slide">
//           <a class="js-modalImg" href="https://placehold.jp/670x414.png">
//             <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//             <p class="m-slider__txt">Parts 11.1</p>
//           </a>
//         </div>
//         <div class="swiper-slide">
//           <a class="js-modalImg" href="https://placehold.jp/670x414.png">
//             <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//             <p class="m-slider__txt">Parts 11.1</p>
//           </a>
//         </div>
//         <div class="swiper-slide">
//           <a class="js-modalImg" href="https://placehold.jp/670x414.png">
//             <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//             <p class="m-slider__txt">Parts 11.1</p>
//           </a>
//         </div>
//         <div class="swiper-slide">
//           <a class="js-modalImg" href="https://placehold.jp/670x414.png">
//             <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//             <p class="m-slider__txt">Parts 11.1</p>
//           </a>
//         </div>
//         <div class="swiper-slide">
//           <a class="js-modalImg" href="https://placehold.jp/670x414.png">
//             <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//             <p class="m-slider__txt">Parts 11.1</p>
//           </a>
//         </div>
//         <div class="swiper-slide">
//           <a class="js-modalImg" href="https://placehold.jp/670x414.png">
//             <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//             <p class="m-slider__txt">Parts 11.1</p>
//           </a>
//         </div>
//         <div class="swiper-slide">
//           <a class="js-modalImg" href="https://placehold.jp/670x414.png">
//             <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//             <p class="m-slider__txt">Parts 11.1</p>
//           </a>
//         </div>
//         <div class="swiper-slide">
//           <a class="js-modalImg" href="https://placehold.jp/670x414.png">
//             <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//             <p class="m-slider__txt">Parts 11.1</p>
//           </a>
//         </div>
//       </div>
//       <div class="swiper-button-prev"></div>
//       <div class="swiper-button-next"></div>
//     </div>
//   </div>
//   <div class="m-sliderOuter__thumb">
//     <div class="m-sliderThumb swiper-container js-sliderThumb01">
//       <div class="swiper-wrapper">
//         <div class="swiper-slide">
//           <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//         </div>
//         <div class="swiper-slide">
//           <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//         </div>
//         <div class="swiper-slide">
//           <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//         </div>
//         <div class="swiper-slide">
//           <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//         </div>
//         <div class="swiper-slide">
//           <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//         </div>
//         <div class="swiper-slide">
//           <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//         </div>
//         <div class="swiper-slide">
//           <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//         </div>
//         <div class="swiper-slide">
//           <img src="https://placehold.jp/670x414.png" alt="Parts 11.1">
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide 11.1

.m-sliderOuter {
  &__slider {
  }
  &__thumb {
    overflow: hidden;
    @include pc {
      margin-top: 10px;
    }
    @include sp {
      margin-top: 16/750*100vw;
    }
  }
}

.m-slider {
  a:focus {
    outline: 0;
  }
  img {
    border-radius: 4px;
    width: 100%;
    @include sp {
      border-radius: 2px;
    }
  }
  &__txt {
    background-color: rgba(#000, .3);
    border-radius: 0 0 4px 4px;
    bottom: 0;
    color: #fff;
    @include fs(13);
    left: 0;
    line-height: 1.5;
    padding: 10px;
    position: absolute;
    width: 100%;
    @include sp {
      border-radius: 0 0 2px 2px;
      @include vw(20);
      padding: 20/750*100vw;
    }
  }
  .swiper-wrapper {
    height: auto;
  }
  .swiper-button-next {
    background-image: none;
    border: 0;
    height: 42px;
    margin-top: 0;
    right: 10px;
    width: 42px;
    transform: rotate(0) translateY(-50%);
    @include sp {
      height: 72/750*100vw;
      right: 4/750*100vw;
      width: 72/750*100vw;
    }
    &:before {
      border-top: 1px solid $color_primary;
      border-right: 1px solid $color_primary;
      content: "";
      display: block;
      height: 30px;
      position: absolute;
      pointer-events: none;
      right: 16px;
      top: 6px;
      transform: rotate(45deg);
      width: 30px;
      @include sp {
        height: 48/750*100vw;
        right: 28/750*100vw;
        top: 12/750*100vw;
        width: 48/750*100vw;
      }
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
  .swiper-button-prev {
    background-image: none;
    border: 0;
    height: 42px;
    left: 10px;
    margin-top: 0;
    width: 42px;
    transform: rotate(0) translateY(-50%);
    @include sp {
      height: 72/750*100vw;
      left: 4/750*100vw;
      width: 72/750*100vw;
    }
    &:before {
      border-top: 1px solid $color_primary;
      border-left: 1px solid $color_primary;
      content: "";
      display: block;
      height: 30px;
      left: 16px;
      position: absolute;
      pointer-events: none;
      top: 6px;
      transform: rotate(-45deg);
      width: 30px;
      @include sp {
        height: 48/750*100vw;
        left: 28/750*100vw;
        top: 12/750*100vw;
        width: 48/750*100vw;
      }
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
  .swiper-slide {
    @include pc {
      height: 247px;
      .m-imgTxt__img--l & {
        height: 328px;
      }
    }
  }
}

.m-sliderThumb {
  margin: -4px -5px;
  img {
    border-radius: 4px;
    width: 100%;
    @include sp {
      border-radius: 2px;
    }
  }
  .swiper-wrapper {
    height: auto;
    flex-wrap: wrap;
    transform: translate(0) !important;
  }
  .swiper-slide-active {
    img {
      border: 1px solid $color_primary;
    }
  }
  .swiper-slide {
    cursor: pointer;
    border-radius: 4px;
    margin: 4px 5px;
    width: calc((100% - 10px * 4 - .01px) / 4) !important;
    @include pc {
      height: 58px;
      .m-imgTxt__img--l & {
        height: 78px;
      }
    }
    @include sp {
      border-radius: 2px;
    }
  }
}
