@charset "utf-8";

.m-planTag {
  &:not(:last-child) {
    margin-bottom: 30px;
    @include sp {
      margin-bottom: 40/750*100vw;
    }
  }
  &__ttl {
    @include fs(18);
    font-weight: 700;
    margin-bottom: 10px;
    @include sp {
      @include vw(28);
      margin-bottom: 30/750*100vw;
    }
  }
}

.m-planTagList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-top: -12px;
  @include sp {
    margin-left: -40/750*100vw;
    margin-top: -40/750*100vw;
  }
  &__item {
    margin-left: 12px;
    margin-top: 12px;
    @include sp {
      margin-left: 40/750*100vw;
      margin-top: 40/750*100vw;
    }
  }
  &__link {
    background-color: $color_bg;
    border-radius: 100vw;
    display: inline-block;
    @include fs(16);
    line-height: 1.5;
    padding: 8px 26px;
    text-align: center;
    @include pc {
      min-width: 118px;
    }
    @include sp {
      @include vw(28);
      min-width: 196/750*100vw;
      padding: 14/750*100vw 26/750*100vw;
    }
  }
}

.m-planTagCenter {
  @include pc {
    display: flex;
    justify-content: center;
  }
  &:not(:last-child) {
    margin-bottom: 80px;
    @include sp {
      @include vw(28);
      margin-bottom: 100/750*100vw;
    }
  }
}

.m-planSection {
  & + & {
    padding-top: 80px;
    @include sp {
      padding-top: 100/750*100vw;
    }
  }
}