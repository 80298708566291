@charset "utf-8";

// リスト
//
// Styleguide 4.0

// 自由[1文字分] (Parts 4.1)
//
// 1文字分の好きな記号をテキスト前に入力
//
// Markup:
// <ul class="m-list">
//   <li class="m-list__item">●Parts 4.1</li>
//   <li class="m-list__item">●Parts 4.1</li>
//   <li class="m-list__item">●Parts 4.1</li>
// </ul>
//
// Styleguide 4.1

// 自由[2文字] (Parts 4.1.1)
//
// 2文字分の好きな記号をテキスト前に入力
//
// Markup:
// <ul class="m-list m-list--2em">
//   <li class="m-list__item">1.　Parts 4.1.1</li>
//   <li class="m-list__item">2.　Parts 4.1.1</li>
//   <li class="m-list__item">3.　Parts 4.1.1</li>
// </ul>
//
// Styleguide 4.1.1

// 自由[3文字] (Parts 4.1.2)
//
// 3文字分の好きな記号をテキスト前に入力
//
// Markup:
// <ul class="m-list m-list--3em">
//   <li class="m-list__item">※1&nbsp;　Parts 4.1.2</li>
//   <li class="m-list__item">※2&nbsp;　Parts 4.1.2</li>
//   <li class="m-list__item">※3&nbsp;　Parts 4.1.2</li>
// </ul>
//
// Styleguide 4.1.2

// 通常[・] (Parts 4.2)
//
// Markup:
// <ul class="m-list m-list--disc">
//   <li class="m-list__item">Parts 4.2</li>
//   <li class="m-list__item">Parts 4.2</li>
//   <li class="m-list__item">Parts 4.2</li>
// </ul>
//
// Styleguide 4.2

// 注釈[※] (Parts 4.3)
//
// Markup:
// <ul class="m-list m-list--note">
//   <li class="m-list__item">Parts 4.3</li>
//   <li class="m-list__item">Parts 4.3</li>
//   <li class="m-list__item">Parts 4.3</li>
// </ul>
//
// Styleguide 4.3

// 上のコンテンツとの余白なし (Parts 4.4)
//
// Markup:
// <ul class="m-list m-list--mt0">
//   <li class="m-list__item">Parts 4.4</li>
//   <li class="m-list__item">Parts 4.4</li>
//   <li class="m-list__item">Parts 4.4</li>
// </ul>
//
// Styleguide 4.4

.m-list {
  &:not(:first-child) {
    margin-top: 16px;
    @include sp {
      margin-top: 24/750*100vw;
    }
  }
  &--mt0 {
    &:not(:first-child) {
      @include pc() {
        margin-top: 0;
      }
      @include sp() {
        margin-top: 0;
      }
    }
  }
  &__item {
    padding-left: 1em;
    text-indent: -1em;
    .m-list--disc > & {
      &:before {
        content: "・";
      }
    }
    .m-list--note > & {
      &:before {
        content: "※";
      }
    }
  }
}