@charset "utf-8";

.p-top {
  &__contents {
    @include pc {
      display: flex;
      justify-content: space-between;
    }
    &:not(:first-child) {
      padding-top: 80px;
      @include sp {
        padding-top: 16/750*100vw;
      }
    }
  }
  &__main {
    @include pc {
      flex: 1 1 auto;
    }
    @include sp {
      padding-top: 96/750*100vw;
    }
  }
  &__side {
    @include pc {
      flex: 0 0 auto;
      margin-right: 60px;
      width: 300px;
    }
  }
}

.p-topKv {
  &__hero {
    @include pc {
      position: relative;
    }
  }
  &__img {
    img {
      width: 100%;
    }
  }
  &__news {
    @include pc {
      background-color: rgba(#000, .5);
      bottom: 0;
      padding: 16px 0;
      position: absolute;
      width: 100%;
    }
    @include sp {
      background-color: $color_bg;
      padding: 20/750*100vw 0;
    }
  }
  &__contact {
    background-color: $color_primary;
    padding: 20px 0;
    @include sp {
      padding: 60/750*100vw 0;
    }
  }
}

.p-topKvNews {
  @include fs(14);
  @include pc {
    color: #fff;
    display: flex;
  }
  @include sp {
    @include vw(22);
  }
  &__date {
    @include pc {
      margin-right: 18px;
    }
    @include sp {
      margin-bottom: 10/750*100vw;
    }
  }
  &__link {
    @include pc {
      color: #fff;
    }
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.p-topKvContact {
  @include pc {
    align-items: center;
    display: flex;
    justify-content: space-between ;
  }
  &__bnr {
    @include pc {
      align-items: center;
      display: flex;
    }
    &:not(:first-child) {
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
    li {
      &:not(:first-child) {
        @include pc {
          margin-left: 20px;
        }
        @include sp {
          margin-top: 20/750*100vw;
        }
      }
    }
  }
}

.p-topCategoryList {
  @include pc {
    display: flex;
    flex-wrap: wrap;
  }
  @include sp {
    margin-left: -40/750*100vw;
    margin-right: -40/750*100vw;
  }
  &__item {
    @include pc {
      border-bottom: 1px solid $color_border;
      border-right: 1px solid $color_border;
      border-top: 1px solid $color_border;
      width: calc(100% / 4);
    }
    @include sp {
      border-bottom: 1px solid $color_border;
    }
    &:first-child {
      @include pc() {
        border-radius: 4px 0 0 0;
      }
      @include sp {
        border-top: 1px solid $color_border;
      }
    }
    &:last-child {
      @include pc() {
        border-radius: 0 0 4px 0;
      }
    }
    &:nth-child(4) {
      @include pc() {
        border-radius: 0 4px 4px 0;
      }
    }
    &:nth-child(5) {
      @include pc() {
        border-radius: 0 0 0 4px;
      }
    }
    &:nth-child(4n + 1) {
      @include pc() {
        border-left: 1px solid $color_border;
      }
    }
    &:nth-child(n + 5) {
      @include pc {
        border-top: 0;
      }
    }
  }
  &__link {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 110px;
    padding-left: 25px;
    padding-right: 15px;
    @include sp {
      justify-content: flex-start;
      height: 120/750*100vw;
      padding-left: 34/750*100vw;
      padding-right: 60/750*100vw;
      position: relative;
    }
    &:after {
      @include sp {
        border-top: 1px solid $color_primary;
        border-right: 1px solid $color_primary;
        box-sizing: border-box;
        content: "";
        display: inline-block;
        height: 18/750*100vw;
        right: 50/750*100vw;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 18/750*100vw;
      }
    }
  }
  &__img {
    flex: 0 0 auto;
    @include sp {
      margin-right: 20/750*100vw;
      order: 1;
    }
    img {
      width: 74px;
      @include sp {
        width: 62/750*100vw;
      }
    }
  }
  &__ttl {
    flex: 0 0 auto;
    @include fs(18);
    font-weight: 700;
    line-height: 1.5;
    @include pc {
      position: relative;
      transition: color .2s ease-in;
    }
    @include sp {
      @include vw(28);
      order: 2;
    }
    .p-topCategoryList__link:hover & {
      @include pc {
        color: $color_primary;
      }
    }
    &:after {
      @include pc {
        border-top: 2px solid $color_primary;
        border-right: 2px solid $color_primary;
        box-sizing: border-box;
        content: "";
        display: inline-block;
        height: 9px;
        right: -14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        transition: transform .2s ease-in;
        width: 9px;
      }
      .p-topCategoryList__link:hover & {
        @include pc {
          transform: translate(8px, -50%) rotate(45deg);
        }
      }
    }
  }
}

.p-topSearch {
  &:not(:first-child) {
    padding-top: 40px;
    @include sp {
      padding-top: 80/750*100vw;
    }
  }
  &__ttl {
    @include fs(18);
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 16px;
    @include sp {
      @include vw(34);
      margin-bottom: 16/750*100vw;
    }
  }
  &__list {
    @include fs(16);
    @include sp {
      @include vw(28);
      display: flex;
      flex-wrap: wrap;
      margin-left: -40/750*100vw;
      margin-top: -40/750*100vw;
    }
    li {
      @include pc {
        border-bottom: 1px solid $color_border;
      }
      @include sp {
        margin-left: 40/750*100vw;
        margin-top: 40/750*100vw;
      }
      &:first-child {
        @include pc {
          border-top: 1px solid $color_border;
        }
      }
    }
    a {
      @include pc {
        align-items: center;
        display: flex;
        @include fs(18);
        height: 56px;
        padding-left: 16px;
        padding-right: 20px;
        position: relative;
        &:after {
          border-top: 2px solid $color_primary;
          border-right: 2px solid $color_primary;
          box-sizing: border-box;
          content: "";
          display: inline-block;
          height: 9px;
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          transition: transform .2s ease-in;
          width: 9px;
        }
      }
      @include sp {
        align-items: center;
        background-color: $color_bg;
        border-radius: 100vw;
        display: flex;
        height: 70/750*100vw;
        justify-content: center;
        min-width: 196/750*100vw;
        padding: 0 30/750*100vw;
      }
      &:hover {
        &:after {
          @include pc {
            transform: translate(8px, -50%) rotate(45deg);
          }
        }
      }
    }
  }
}

.p-topContact {
  margin-bottom: 30px;
  @include sp {
    margin-bottom: 60/750*100vw;
  }
}

.p-topNews {
  @include pc {
    position: relative;
  }
  &__btn {
    @include pc {
      position: absolute;
      right: 0;
      top: 6px;
    }
    @include sp {
      margin-top: 60/750*100vw;
    }
  }
}

.p-topUseful {
  &__item {
    align-items: center;
    background-color: $color_bg;
    border-radius: 4px;
    display: flex;
    padding: 25px;
    @include pc {
      position: relative;
    }
    @include sp {
      border-radius: 2px;
      padding: 40/750*100vw;
    }
    &:not(:first-child) {
      margin-top: 20px;
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
  &__img {
    flex: 0 0 auto;
    margin-right: 25px;
    @include sp {
      margin-right: 40/750*100vw;
    }
    img {
      width: 140px;
      @include sp {
        width: 160/750*100vw;
      }
    }
  }
  &__contents {
    flex: 1 1 auto;
  }
  &__ttl {
    @include fs(18);
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1.5;
    @include sp {
      margin-bottom: 10/750*100vw;
      @include vw(34);
    }
  }
  &__txt {
    color: $color_txtSub;
    @include fs(14);
    @include sp {
      @include vw(22);
    }
  }
  &__btn {
    text-align: right;
    @include pc {
      bottom: 14px;
      position: absolute;
      right: 20px;
    }
    @include sp {
      margin-top: 10/750*100vw;
    }
  }
}

.p-topGuide {
  @include pc() {
    display: flex;
    flex-wrap: wrap;
  }
  @include sp {
    margin-left: -40/750*100vw;
    margin-right: -40/750*100vw;
  }
  &__item {
    @include pc() {
      border: 1px solid $color_border;
      border-radius: 4px;
      margin-left: 20px;
      padding: 20px 17px 50px 17px;
      width: calc((100% - 20px) / 2);
      position: relative;
    }
    @include sp {
      border-bottom: 1px solid $color_border;
    }
    &:nth-child(2n + 1) {
      @include pc() {
        margin-left: 0;
      }
    }
    &:nth-child(n + 3) {
      @include pc {
        margin-top: 20px;
      }
    }
    &:first-child {
      @include sp {
        border-top: 1px solid $color_border;
      }
    }
  }
  &__contents {
    @include pc {
      display: block !important;
    }
    @include sp {
      display: none;
      padding-bottom: 60/750*100vw;
      padding-left: 30/750*100vw;
      padding-right: 30/750*100vw;
    }
  }
  &__ttl {
    @include fs(18);
    font-weight: 700;
    line-height: 1.5;
    @include pc {
      pointer-events: none;
      margin-bottom: 10px;
    }
    @include sp {
      @include vw(28);
      padding: 38/750*100vw 30/750*100vw;
      position: relative;
    }
    &:before {
      @include sp {
        content: "";
        display: block;
        border-top: solid 1px $color_primary;
        position: absolute;
        right: 40/750*100vw;
        transform: translateY(-50%);
        top: 50%;
        width: 32/750*100vw;
        z-index: 2;
      }
    }
    &:after {
      @include sp {
        content: "";
        display: block;
        border-top: solid 1px $color_primary;
        right: 40/750*100vw;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        transition: transform .2s ease-in;
        width: 32/750*100vw;
        z-index: 2;
      }
    }
    &.is-open {
      &:after {
        @include sp {
          transform: translateY(-50%) rotate(0);
        }
      }
    }
  }
  &__txt {
    color: $color_txtSub;
    @include fs(14);
    @include sp {
      @include vw(22);
    }
  }
  &__ttlLv2 {
    @include fs(13);
    font-weight: 700;
    margin-bottom: 6px;
    @include sp {
      @include vw(22);
      margin-bottom: 12/750*100vw;
    }
  }
  &__img {
    margin-bottom: 10px;
    @include sp {
      margin-bottom: 20/750*100vw;
    }
  }
  &__btn {
    text-align: right;
    @include pc {
      bottom: 14px;
      position: absolute;
      right: 20px;
    }
    @include sp {
      margin-top: 40/750*100vw;
    }
  }
}

.p-topFollow {
  @include pc {
    border-top: 1px solid $color_border;
  }
  @include sp {
    margin-top: -100/750*100vw;
  }
  &__section {
    padding: 60px 0;
    @include sp {
      padding: 80/750*100vw 0;
    }
  }
  .m-ttlLv2 {
    text-align: center;
  }
  .m-sns {
    justify-content: center;
    &__item {
      &:not(:first-child) {
        @include pc {
          margin-left: 40px;
        }
      }
      img {
        @include pc {
          height: 30px;
        }
      }
    }
  }
}