@charset "utf-8";
.m-accordion {
  &__ttl {
    cursor: pointer;
    // @include fs(16);
    // // font-weight: 700;
    // line-height: 1.5;
    // padding: 26px 80px 26px 80px;
    position: relative;
    transition: color .2s ease-in;
    // @include sp {
    //   @include vw(28);
    //   padding: 62/750*100vw 70/750*100vw 62/750*100vw 100/750*100vw;
    // }
    // &:hover {
    //   color: $color_primary;
    // }
    &:before {
      content: "";
      display: block;
      border-top: solid 2px $color_primary;
      position: absolute;
      right: 40px;
      transform: translateY(-50%);
      top: 50%;
      width: 20px;
      z-index: 2;
      @include sp {
        border-width: 1px;
        right: 40/750*100vw;
        width: 32/750*100vw;
      }
    }
    &:after {
      content: "";
      display: block;
      border-top: solid 2px $color_primary;
      right: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      transition: transform .2s ease-in;
      width: 20px;
      z-index: 2;
      @include sp {
        content: "";
        border-width: 1px;
        right: 40/750*100vw;
        width: 32/750*100vw;
      }
    }
    &.is-open {
      &:after {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
  &__contents {
    display: none;
  }
}
