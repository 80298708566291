@charset "utf-8";

.wp-pagenavi {
  align-items: s;
  display: flex;
  font-family: $font-family-primary;
  @include fs(18);
  font-weight: 700;
  justify-content: center;
  margin-top: 60px;
  @include sp {
    @include vw(34);
    margin-top: 70/750*100vw;
  }
  a,
  span {
    align-items: center;
    border-bottom: 2px solid transparent;
    color: #CCC;
    display: inline-flex;
    padding: 0 2px;
    line-height: 1;
    height: 40px;
    @include sp {
      height: 80/750*100vw;
      padding: 0 4/750*100vw;
    }
    &:not(:first-child) {
      margin-left: 16px;
      @include sp {
        margin-left: 30/750*100vw;
      }
    }
    &.previouspostslink,
    &.nextpostslink {
      color: $color_txt;
      @include fs(16);
      font-family: $font-family-basic;
      font-weight: 400;
      @include sp {
        @include vw(28);
      }
    }
  }
  .current {
    border-color: $color_primary;
    color: $color_primary;
    pointer-events: none;
  }
  .extend {
    color: $color_txt;
  }
}
