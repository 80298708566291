@charset "utf-8";

// テキスト
//
// Styleguide 3.0

// 通常 (Parts 3.1)
//
// Markup:
// <p class="m-txt">Parts 3.1</p>
//
// Styleguide 3.1

// 強調 (Parts 3.2)
//
// Markup:
// <p class="m-txt m-txt--strong">Parts 3.2</p>
//
// Styleguide 3.2

// 抑制 (Parts 3.3)
//
// Markup:
// <p class="m-txt m-txt--light">Parts 3.3</p>
//
// Styleguide 3.3

// カラー (Parts 3.4)
//
// Markup:
// <p class="m-txt m-txt--primary">Parts 3.4</p>
//
// Styleguide 3.4

// エラー (Parts 3.5)
//
// Markup:
// <p class="m-txt m-txt--error">Parts 3.5</p>
//
// Styleguide 3.5

// 文字サイズ小 (Parts 3.6)
//
// Markup:
// <p class="m-txt m-txt--s">Parts 3.6</p>
//
// Styleguide 3.6

// 文字サイズ大 (Parts 3.7)
//
// Markup:
// <p class="m-txt m-txt--l">Parts 3.7</p>
//
// Styleguide 3.7

.m-txt {
  &:not(:first-child) {
    margin-top: 16px;
    @include sp {
      margin-top: 24/750*100vw;
    }
    .m-ttlLv1 + & {
      margin-top: 40px;
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
  &--s {
    @include fs(14);
    @include sp {
      @include vw(22);
    }
  }
  &--l {
    @include fs(18);
    @include sp {
      @include vw(32);
    }
  }
  &--strong {
    font-weight: 700;
  }
  &--light {
    color: $color_txtSub;
  }
  &--primary {
    color: $color_primary;
  }
  &--error {
    color: $color_attention;
  }
}