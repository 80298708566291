/**
 * initiallize
 * タグ自体のstyleを記述
 */

html {
  font-family: $font-family-basic;
  color: $color_txt;
  font-size: $base_fs * 1px;
  word-wrap: break-word;
}


body {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  letter-spacing: .04em;
  @include pc {
    @include fs(16);
    line-height: 1.8;
    min-width: #{$max-width}px;
  }
  @include sp {
    @include vw(28);
    line-height: 1.6;
  }
  &.is-fixed {
    @include sp {
      height: 100%;
      overflow: hidden;
    }
  }
}

a {
  text-decoration: $link_deco;
  color: $color_lnk;
  transition: all 0.2s;
  &:hover {
    text-decoration: none;
    color: $color_primary;
    img {
      opacity: .8;
    }
  }
}
img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  @include sp{
    width: 100%;
  }
}

strong {
  font-weight: bold;
}

input {
  padding: 0;
  border: none;
  background: none;
}
*{
  box-sizing: border-box;
  transition: font-size 0.1s linear;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;

}
input[type=text],
input[type=tel],
input[type=password],
input[type=email],
input[type=search],
input[type=url],
input[type=datetime],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime-local],
input[type=number],
select,
textarea {
  padding: 12px 18px;
  @include fs(16);
  font-family: $font-family-basic;
  background: #fff;
  border: 1px solid $color_borderEnclosure;
  border-radius: 4px;
  width: 100%;
  vertical-align: bottom;
  @include sp {
    border-radius: 2px;
    padding: 30/750*100vw 20/750*100vw;
  }
  &::-webkit-input-placeholder {
    color: $color_placeholder;
    @include fs(13);
    @include sp {
      @include fs(12);
    }
  }
  &:-ms-input-placeholder {
    color: $color_placeholder;
    @include fs(13);
    @include sp {
      @include fs(12);
    }
  }
  &::-moz-placeholder {
    color: $color_placeholder;
    @include fs(13);
    @include sp {
      @include fs(12);
    }
  }
  &:focus {
    background-color: #eef4fb;
    outline: 0;
  }
}

textarea {
  height: 290px;
  @include sp {
    height: 460/750*100vw;
  }
}

input[type=radio],
input[type=checkbox] {
  margin: 0;
  vertical-align: -1px;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer;
  font-family: $font-family-basic;
  vertical-align: bottom;
}

textarea {
  resize: vertical;
  vertical-align: bottom;
}

em {
  font-style: italic;
}

sup {
  font-size: .5em;
  vertical-align: super;
}

sub {
  font-size: .5em;
  vertical-align: sub;
}

blockquote{
  background-color: #EEEFFF;
  padding: 1em 1em 1em 3em;
  position: relative;
  border-left: 3px solid #666;
}
blockquote:before{
  content: "“";
  font-size: 600%;
  line-height: 1em;
  color: #999;
  position: absolute;
  left: 0;
  top: 0;
}

::selection {
  background: #d4dcd6; /* Safari */
}
::-moz-selection {
  background: #d4dcd6; /* Firefox */
}
