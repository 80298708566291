@charset "utf-8";

// 見出し
//
// classでスタイル記述しているため、hタグの種類には依存しない。<br>
// その見出しにあったhタグで適宜実装。
//
// Styleguide 2.0

// 見出し Lv1 (Parts 2.1)
//
// サブ見出しはトルツメ可能。見出しの下だけでなく、上にも配置可能。<br>
// 英語のフォントを使用したい場合は、`m-ttlLv1__main--en`、`m-ttlLv1__sub--en`を付与。
//
// Markup:
// <h1 class="m-ttlLv1">
//   <span class="m-ttlLv1__main m-ttlLv1__main--en">Parts 2.1</span>
//   <span class="m-ttlLv1__sub">SUB</span>
// </h1>
//
// Styleguide 2.1

// 見出し Lv2 (Parts 2.2)
//
// サブ見出しはトルツメ可能。<br>
// 英語のフォントを使用したい場合は、`m-ttlLv2--en`、`m-ttlLv2__sub--en`を付与。
//
// Markup:
// <h2 class="m-ttlLv2 m-ttlLv2--en">Parts 2.2<span class="m-ttlLv2__sub">SUB</span></h2>
//
// Styleguide 2.2

// 見出し Lv2 下線 (Parts 2.3)
//
// WordPressの投稿のh2にはこのスタイルが反映
//
// Markup:
// <h2 class="m-ttlBorderLv2">Parts 2.3</h2>
//
// Styleguide 2.3

// 見出し Lv2 中央 (Parts 2.4)
//
// Markup:
// <h2 class="m-ttlLv2Center">Parts 2.4</h2>
//
// Styleguide 2.4

// 見出し Lv3 (Parts 2.5)
//
// WordPressの投稿のh3にはこのスタイルが反映
//
// Markup:
// <h3 class="m-ttlLv3">Parts 2.5</h3>
//
// Styleguide 2.5

// 見出し Lv4 (Parts 2.6)
//
// Markup:
// <h4 class="m-ttlLv4">Parts 2.6</h4>
//
// Styleguide 2.6

// 見出し横並び (Parts 2.7)
//
// Markup:
// <p class="m-ttlFlex">
//   <span class="m-ttlFlex__ttl">TTL：</span>
//   <span class="m-ttlFlex__ttl">Parts 2.7</span>
// </p>
//
// Styleguide 2.7

.m-ttlLv1 {
  @include fs(30);
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  @include sp {
    @include vw(50);
  }
  &__main {
    display: block;
    @include fs(36);
    font-weight: 700;
    @include sp {
      @include vw(50);
    }
    &--en {
      font-family: $font-family-primary;
      @include fs(40);
      font-weight: 700;
      @include sp {
        @include vw(50);
      }
    }
  }
  &__sub {
    color: $color_primary;
    display: block;
    @include fs(18);
    font-weight: 700;
    @include sp {
      @include vw(28);
    }
    &--en {
      font-family: $font-family-primary;
    }
    &:not(:first-child) {
      margin-top: 8px;
      @include sp {
        margin-top: 4/750*100vw;
      }
    }
    &:not(:last-child) {
      margin-bottom: 8px;
      @include sp {
        margin-bottom: 4/750*100vw;
      }
    }
  }
}

.m-ttlLv2 {
  @include fs(30);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 18px;
  @include sp {
    @include vw(50);
    margin-bottom: 40/750*100vw;
  }
  &--en {
    font-family: $font-family-primary;
  }
  &__sub {
    color: $color_primary;
    @include fs(16);
    font-weight: 500;
    font-family: $font-family-basic;
    @include pc {
      margin-left: 16px;
    }
    @include sp {
      display: block;
      @include vw(28);
      margin-top: 4/750*100vw;
    }
    &--en {
      font-family: $font-family-primary;
    }
  }
}

.m-ttlBorderLv2 {
  @include fs(30);
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 30px;
  padding-bottom: 20px;
  position: relative;
  @include sp {
    @include vw(40);
    margin-bottom: 30/750*100vw;
    padding-bottom: 40/750*100vw;
  }
  &:before {
    background: linear-gradient(90deg, $color_primary 0%, $color_primary 100px, $color_border 100px, $color_border 100%);
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    @include sp{
      background: linear-gradient(90deg, $color_primary 0%, $color_primary 200/750*100vw, $color_border 200/750*100vw, $color_border 100%);
    }
  }
  &__sub {
    color: $color_primary;
    display: inline-block;
    @include fs(16);
    @include pc {
      margin-left: 10px;
    }
    @include sp {
      @include vw(20);
      margin-left: 4/750*100vw;
    }
  }
}

.m-ttlLv2Center {
  @include fs(30);
  font-weight: 700;
  text-align: center;
  @include sp {
    @include vw(40);
  }
  &:not(:last-child) {
    margin-bottom: 40px;
    @include sp {
      margin-bottom: 40/750*100vw;
    }
  }
  &__sub {
    color: $color_primary;
    display: inline-block;
    @include fs(16);
    @include pc {
      margin-left: 10px;
    }
    @include sp {
      @include vw(20);
      margin-left: 4/750*100vw;
    }
  }
}

.m-ttlLv3 {
  @include fs(24);
  font-weight: 700;
  @include sp {
    @include vw(34);
  }
  &:not(:last-child) {
    margin-bottom: 20px;
    @include sp {
      margin-bottom: 20/750*100vw;
    }
  }
}

.m-ttlLv4 {
  @include fs(18);
  font-weight: 700;
  @include sp {
    @include vw(32);
  }
  &:not(:last-child) {
    margin-bottom: 16px;
    @include sp {
      margin-bottom: 24/750*100vw;
    }
  }
}

.m-ttlFlex {
  display: flex;
  &__ttl {
    flex: 0 0 auto;
  }
  &__txt {
    flex: 1 1 auto;
  }
}