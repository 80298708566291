@charset "utf-8";

// リンク
//
// Styleguide 6.0

// 通常リンク (Parts 6.1)
//
// Markup:
// <a class="m-link" href="">Parts 6.1</a>
//
// Styleguide 6.1

// 汎用的なリンクを囲む要素 (Parts 6.2)
//
// リンクが左寄せに配置されます。
//
// Markup:
// <div class="m-linkOuter">
//   <a class="m-link" href="">Parts 6.2</a>
// </div>
//
// Styleguide 6.2

.m-link {
  display: inline-block;
  @include fs(14);
  font-weight: 400;
  padding-right: 16px;
  position: relative;
  @include sp {
    @include vw(28);
    padding-right: 30/750*100vw;
  }
  &:after {
    border-top: 2px solid $color_primary;
    border-right: 2px solid $color_primary;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    height: 9px;
    width: 9px;
    transform: translateY(-50%) rotate(45deg);
    transition: transform .2s ease-in;
    position: absolute;
    top: 50%;
    right: 0;
    @include sp {
      border-width: 1px;
      height: 18/750*100vw;
      right: 0;
      width: 18/750*100vw;
    }
  }
  &:hover {
    &:after {
      @include pc {
        transform: translate(8px, -50%) rotate(45deg);
      }
    }
  }
}

.m-linkOuter {
  text-align: right;
  &:not(:first-child) {
    margin-top: 16px;
    @include sp {
      margin-top: 40/750*100vw;
    }
  }
}