@charset "utf-8";

.m-wpContents {
  
}

.m-wpHeading {
    margin-bottom: 60px;
    @include sp {
      margin-bottom: 60/750*100vw;
    }
  &__ttl {
    @include fs(36);
    font-weight: 700;
    line-height: 1.5;
    @include sp {
      @include vw(50);
    }
  }
  &__date {
    color: $color_txtSub;
    display: block;
    @include fs(16);
    margin-top: 20px;
    @include sp {
      @include vw(28);
      margin-top: 20/750*100vw;
    }
  }
}

