@charset "utf-8";

.l-nav {
  @include pc {
    display: flex;
  }
  &__btn {
    @include sp {
      display: flex;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }
  &__contents {
    @include pc {
      align-items: center;
      display: flex;
    }
    @include sp {
      background-color: $color_primary;
      display: block;
      height: calc(100vh - 100/750*100vw);
      left: 0;
      overflow: auto;
      position: fixed;
      top: 110/750*100vw;
      transform: translateX(100%);
      transition: transform .4s ease-out;
      width: 100%;
    }
    .is-openMenu & {
      z-index: 1;
      @include sp {
        transform: translateX(0);
      }
    }
  }
  &__searchContents {
    @include pc {
      align-items: center;
      display: flex;
      margin-left: 40px;
    }
    @include sp {
      background-color: $color_primary;
      display: block;
      height: calc(100vh - 100/750*100vw);
      left: 0;
      overflow: auto;
      position: fixed;
      top: 110/750*100vw;
      transform: translateX(100%);
      transition: transform .4s ease-out;
      width: 100%;
    }
    .is-openSearch & {
      z-index: 1;
      @include sp {
        transform: translateX(0);
      }
    }
    .l-navMenu__link {
      @include sp {
        display: none;
      }
    }
    .l-navMenu__contents {
      @include sp {
        display: block;
      }
    }
  }
}

.l-navBtn {
  align-items: flex-end;
  background: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  height: 110/750*100vw;
  justify-content: center;
  padding-bottom: 20/750*100vw;
  width: 110/750*100vw;
  z-index: 1;
  @include pc {
    display: none;
  }
  &__open {
    display: block;
    width: 56/750*100vw;
    .is-openMenu & {
      display: none;
    }
  }
  &__close {
    display: none;
    width: 62/750*100vw;
    .is-openMenu & {
      display: block;
    }
  }
}

.l-navBtnSearch {
  align-items: flex-end;
  background: #fff;
  border: none;
  border-left: 2/750*100vw solid #EEE;
  border-right: 2/750*100vw solid #EEE;
  cursor: pointer;
  display: flex;
  height: 110/750*100vw;
  justify-content: center;
  padding-bottom: 20/750*100vw;
  width: 114/750*100vw;
  z-index: 1;
  @include pc {
    display: none;
  }
  &__open {
    display: block;
    width: 77/750*100vw;
    .is-openSearch & {
      display: none;
    }
  }
  &__close {
    display: none;
    width: 62/750*100vw;
    .is-openSearch & {
      display: block;
    }
  }
}

.l-navMenu {
  @include pc {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  &__contents {
    display: none;
    @include pc {
      display: none !important;
    }
    .l-navMenu__item:hover & {
      @include pc {
        background-color: #F5F5F5;
        box-shadow: 0 5px 10px rgba(#000, .05) inset;
        display: block !important;
        position: fixed;
        top: 80px;
        left: 0;
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
      }
    }
    @include sp {
      background-color: #fff;
      box-shadow: 0 2.5px 5px rgba(#000, .05) inset;
      padding-bottom: 60/750*100vw;
      padding-top: 60/750*100vw;
    }
  }
  &__inner {
    @include pc {
      display: block !important;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
      width: calc(#{$width-large}px + 40px);
    }
    @include sp {
      padding-left: 40/750*100vw;
      padding-right: 40/750*100vw;
    }
  }
  &__ttl {
    color: $color_primary;
    @include fs(18);
    font-weight: 700;
    margin-bottom: 20px;
    @include sp {
      display: block;
      @include vw(34);
      margin-bottom: 50/750*100vw;
    }
    &:not(:first-child) {
      margin-top: 40px;
      @include sp {
        margin-top: 70/750*100vw;
      }
    }
    a {
      color: $color_primary;
      &:hover {
        opacity: .8;
      }
    }
  }
  &__item {
    @include pc {
      align-items: center;
      display: flex;
      position: relative;
    }
    @include sp {
      border-bottom: 1px solid #fff;
      position: relative;
    }
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
      }
    }
  }
  &__link {
    cursor: pointer;
    @include pc {
      align-items: center;
      display: flex;
      height: 80px;
      line-height: 1;
      position: relative;
      @include fs(16);
      overflow: hidden;
    }
    @include sp {
      color: #fff;
      background-color: $color_primary;
      display: block;
      @include vw(26);
      padding: 38/750*100vw 100/750*100vw 38/750*100vw 34/750*100vw;
    }
    &:after {
      @include pc {
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: solid 4px $color_primary;
        content: "";
        display: inline-block;
        width: 100%;
        transform: translateX(-101%);
        transition: transform .6s ease-out;
      }
    }
    &:hover {
      &:after {
        @include pc {
          transform: translateX(0);
        }
      }
    }
    .l-navMenu__item--search & {
      @include sp {
        transform: translateX(0);
      }
    }
  }
  &__icnOff {
    margin: 0 10px;
    width: 20px;
    @include pc {
      display: block;
    }
    .l-navMenu__link:hover & {
      @include pc {
        display: none;
      }
    }
  }
  &__icnOn {
    margin: 0 10px;
    width: 20px;
    @include pc {
      display: none;
    }
    .l-navMenu__link:hover & {
      @include pc {
        display: block;
      }
    }
  }
  &__btn {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 0;
    z-index: 1;
    @include pc() {
      display: none;
    }
    @include sp() {
      left: 0;
      top: 0;
      width: 100%;
      height: 120/750*100vw;
      z-index: 2;
    }
    &:before {
      content: "";
      display: block;
      border-top: solid 1px #fff;
      position: absolute;
      right: 30/750*100vw;
      top: 60/750*100vw;
      width: 40/750*100vw;
    }
    &:after {
      content: "";
      display: block;
      border-top: solid 1px #fff;
      position: absolute;
      right: 30/750*100vw;
      top: 60/750*100vw;
      transform: rotate(90deg);
      transition: transform .2s ease-in;
      width: 40/750*100vw;
    }
    &.is-open {
      &:after {
        transform: rotate(0);
      }
    }
  }
}

.l-navMenuLv2 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  margin-top: -10px;
  @include sp {
    margin-left: -116/750*100vw;
    margin-top: -50/750*100vw;
  }
  &__item {
    margin-left: 40px;
    margin-top: 10px;
    @include sp {
      margin-left: 116/750*100vw;
      margin-top: 50/750*100vw;
      width: calc((100% - (116/750*100vw) * 2) / 2);
    }
  }
  &__link {
    display: inline-block;
    @include fs(16);
    padding-right: 16px;
    position: relative;
    @include sp {
      display: block;
      @include vw(28);
    }
    &:before {
      border-top: 2px solid $color_primary;
      border-right: 2px solid $color_primary;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 9px;
      width: 9px;
      transform: translateY(-50%) rotate(45deg);
      transition: transform .2s ease-in;
      position: absolute;
      top: 50%;
      right: 0;
      @include sp {
        border-width: 1px;
        height: 18/750*100vw;
        right: 0;
        width: 18/750*100vw;
      }
    }
    &:hover {
      &:before {
        @include pc {
          transform: translate(8px, -50%) rotate(45deg);
        }
      }
    }
  }
}

.l-navMenContact {
  @include pc {
    align-items: center;
    display: flex;
  }
  .m-contact__item {
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
        margin-top: 0;
      }
    }
  }
}