@charset "utf-8";
.m-breadcrumb {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  li {
    @include pc() {
      @include fs(13);
    }
    @include sp() {
      @include vw(22);
    }
    a {
      &:hover {  
        text-decoration: underline;
      }
    }
    &:not(:last-child) {
      &:after {
        content: "＞";
        display: inline-block;
        margin-left: .5em;
        margin-right: .5em;
      }
    }
  }
}