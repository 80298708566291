@charset "utf-8";

.m-contact {
  margin-bottom: 30px;
  @include sp {
    margin-bottom: 60/750*100vw;
  }
  &__item {
    font-family: $font-family-primary;
    font-weight: 500;
    @include fs(17);
    line-height: 1;
    @include sp {
      @include vw(34);
    }
    &:not(:first-child) {
      @include pc {
        margin-top: 16px;
      }
      @include sp {
        margin-top: 38/750*100vw;
      }
    }
  }
  &__link {
    align-items: center;
    display: flex;
    &--center {
      justify-content: center;
    }
  }
  &__icn {
    margin-right: 8px;
    @include sp {
      margin-right: 20/750*100vw;
    }
    img {
      width: 24px;
      @include sp {
        width: 54/750*100vw;
      }
    }
  }
}