@charset "utf-8";

// 動画
//
// Styleguide 14.0

// 通常 (Parts 14.1)
//
// Markup:
// <figure class="m-video">
//   <iframe src="https://youtube.com/embed/y9YV_0amGHg" allowfullscreen=""></iframe>
// </figure>
//
// Styleguide 14.1

.m-video {
  position: relative;
  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
  &:not(:first-child) {
    margin-top: 16px;
    @include sp {
      margin-top: 24/750*100vw;
    }
  }
  iframe {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
}