@charset "utf-8";

// 余白
//
// Styleguide 99.3

// Lv1  (Parts 99.3.1)
//
// Markup:
// <div class="l-section">Parts 99.3.1</div>
// <div class="l-section">Parts 99.3.1</div>
//
// Styleguide 99.3.1

// Lv2 (Parts 99.3.2)
//
// Markup:
// <div class="l-sectionLv2">Parts 99.3.2</div>
// <div class="l-sectionLv2">Parts 99.3.2</div>
//
// Styleguide 99.3.2

// Lv3 (Parts 99.3.3)
//
// Markup:
// <div class="l-sectionLv3">Parts 99.3.3</div>
// <div class="l-sectionLv3">Parts 99.3.3</div>
//
// Styleguide 99.3.3

.l-section {
  &:not(:first-child) {
    padding-top: 80px;
    @include sp {
      padding-top: 100/750*100vw;
    }
    .m-ttlLv1 + & {
      padding-top: 80px;
      @include sp {
        padding-top: 60/750*100vw;
      }
    }
  }
}

.l-sectionLv2 {
  &:not(:first-child) {
    padding-top: 60px;
    @include sp {
      padding-top: 80/750*100vw;
    }
  }
}

.l-sectionLv3 {
  &:not(:first-child) {
    padding-top: 40px;
    @include sp {
      padding-top: 60/750*100vw;
    }
  }
}
