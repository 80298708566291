@charset "utf-8";

.m-newsList {
  line-height: 1.5;
  &__item {
    border-bottom: 1px solid $color_border;
    &:first-child {
      border-top: 1px solid $color_border;
    }
  }
  &__link {
    position: relative;
    @include pc {
      display: flex;
      padding: 28px 60px 28px 20px;
    }
    @include sp {
      display: block;
      padding: 30/750*100vw 68/750*100vw 30/750*100vw 0;
    }
    &:after {
      border-top: 2px solid $color_primary;
      border-right: 2px solid $color_primary;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 9px;
      width: 9px;
      transform: translateY(-50%) rotate(45deg);
      transition: transform .2s ease-in;
      position: absolute;
      top: 50%;
      right: 28px;
      @include sp {
        border-width: 1px;
        height: 18/750*100vw;
        right: 0;
        width: 18/750*100vw;
      }
    }
    &:hover {
      &:after {
        @include pc {
          transform: translate(8px, -50%) rotate(45deg);
        }
      }
    }
    .m-newsList--noArrow & {
      @include pc {
        display: flex;
        padding:28px 16px;
      }
      @include sp {
        display: block;
        padding: 30/750*100vw 0;
      }
      &:after {
        content: none;
      }
    }
  }
  &__date {
    display: block;
    @include pc {
      flex: 0 0 auto;
      margin-right: 22px;
    }
    @include sp {
      margin-bottom: 10/750*100vw;
    }
  }
  &__ttl {
    @include pc {
      flex: 1 1 auto;
    }
  }
}