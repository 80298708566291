@charset "utf-8";

// コンテンツ幅
//
// Styleguide 99.2

// 通常 (Parts 99.2.1)
//
// 下層ページのコンテンツエリア
//
// Markup:
// <div class="l-contents">Parts 99.2.1</div>
//
// Styleguide 99.2.1

// サイズ小 (Parts 99.2.2)
//
// 記事ページのコンテンツエリア
//
// Markup:
// <div class="l-contents--narrow">Parts 99.2.2</div>
//
// Styleguide 99.2.2

// サイズ大 (Parts 99.2.3)
//
// TOPページのコンテンツエリア
//
// Markup:
// <div class="l-contents--large">Parts 99.2.3</div>
//
// Styleguide 99.2.3

.l-contents {
  @include pc {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(#{$width}px + 40px);
  }
  @include sp {
    padding-left: 40/750*100vw;
    padding-right: 40/750*100vw;
  }
  &--large {
    @include pc {
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
      width: calc(#{$width-large}px + 40px);
    }
    @include sp {
      padding-left: 40/750*100vw;
      padding-right: 40/750*100vw;
    }
  }
  &--narrow {
    @include pc {
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
      width: calc(#{$width-narrow}px + 40px);
    }
    @include sp {
      padding-left: 40/750*100vw;
      padding-right: 40/750*100vw;
    }
  }
}
