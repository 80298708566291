@charset "utf-8";

.m-formBtn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  overflow: hidden;
  @include sp {
    width: 100%;
  }
  &:after {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    height: 9px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transition: transform .2s ease-in;
    width: 9px;
    @include sp {
      border-width: 1px;
      height: 18/750*100vw;
      right: 32/750*100vw;
      width: 18/750*100vw;
    }
  }
  input {
    align-items: center;
    background-color: $color_primary;
    border: 1px solid $color_primary;
    border-radius: 4px;
    color: #fff;
    display: inline-flex;
    @include fs(16);
    font-family: $font-family-primary;
    justify-content: center;
    line-height: 1;
    padding: 21px 0;
    width: 300px;
    position: relative;
    cursor: pointer;
    @include sp {
      border-radius: 2px;
      @include vw(28);
      padding: 48/750*100vw 0;
      width: 100%;
    }
  }
  &:hover {
    input {
      color: #fff;
    }
    &:after {
      @include pc {
        transform: translate(8px, -50%) rotate(45deg);
      }
    }
  }
  &--anc {
    &:after {
      transform: translate(0, calc(-50% - 2px)) rotate(135deg);
    }
    &:hover {
      &:after {
        @include pc {
          transform: translate(0, calc(-50% + 2px)) rotate(135deg);
        }
      }
    }
  }
  &--clW {
    input {
      background-color: #fff;
      border-color: $color_txt;
      color: $color_txt;
    }
    &:after {
      border-color: $color_primary;
    }
    &:hover {
      input {
        color: $color_primary;
      } 
    }
  }
  &--s {
    input {
      width: 260px;
      @include sp() {
        padding: 36/750*100vw 0;
        width: 384/750*100vw;
      }
    }
  }
  &--l {
    input {
      @include pc() {
        width: 420px;
      }
    }
  }
  &--wide {
    input {
      width: 100%;
    }
  }
  &--icnLeft {
    &:after {
      left: 20px;
      transform: translateY(-50%) rotate(225deg);
      @include sp {
        left: 32/750*100vw;
        width: 18/750*100vw;
      }
    }
    &:hover {
      &:after {
        @include pc {
          transform: translate(-8px, -50%) rotate(225deg);
        }
      }
    }
  }
}