@charset "utf-8";
.l-header {
  align-items: center;
  background-color: #fff;
  left: 0;
  position: fixed;
  top: 0;
  transition: padding-top .4s ease-in;
  width: 100%;
  z-index: 100;
  @include sp {
    padding-left: 40/750*100vw;
  }
  &.is-scroll {
    @include pc {
      box-shadow: 0 5px 10px rgba(#000, .05);
    }
    @include sp {
      box-shadow: 0 2.5px 5px rgba(#000, .05);
    }
  }
  &__inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    @include pc {
      margin-left: auto;
      margin-right: auto;
      min-width: #{$max-width}px;
      padding-left: 20px;
      padding-right: 30px;
      position: relative;
    }
    @include sp {
      height: 110/750*100vw;
    }
  }
  &__logo {
    @include pc {
      flex: 0 0 auto;
      margin-right: 20px;
    }
    img {
      @include pc {
        width: 228px;
      }
      @include sp {
        width: 358/750*100vw;
      }
    }
  }
}
