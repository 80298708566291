@charset "utf-8";

.m-btnSection {
  @include pc {
    position: relative;
  }
  &__btn {
    @include pc {
      position: absolute;
      right: 0;
      top: 10px;
    }
    @include sp {
      margin-top: 60/750*100vw;
    }
  }
}
