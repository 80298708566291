@charset "utf-8";
.m-formRequired {
  background-color: #fff;
  border: 1px solid $color_attention;
  border-radius: 4px;
  color: $color_attention;
  display: inline-block;
  flex: 0 0 auto;
  @include fs(13);
  font-weight: 400;
  line-height: 1;
  margin-left: 5px;
  margin-top: -2px;
  padding: 5px 7px;
  vertical-align: middle;
  @include sp {
    border-radius: 2px;
    @include vw(22);
    margin-left: 20/750*100vw;
    margin-top: -4/750*100vw;;
    padding: 6/750*100vw 8/750*100vw;
  }
}
