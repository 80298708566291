@charset "utf-8";

// ボタン
//
// Styleguide 5.0

// 通常ボタン (Parts 5.1)
//
// Markup:
// <a class="m-btn" href="">Parts 5.1</a>
//
// Styleguide 5.1

// サイズ小 (Parts 5.1.1)
//
// Markup:
// <a class="m-btn m-btn--s" href="">Parts 5.1.1</a>
//
// Styleguide 5.1.1

// サイズ大 (Parts 5.1.2)
//
// Markup:
// <a class="m-btn m-btn--l" href="">Parts 5.1.2</a>
//
// Styleguide 5.1.2

// サイズ幅100% (Parts 5.1.3)
//
// Markup:
// <a class="m-btn m-btn--wide" href="">Parts 5.1.3</a>
//
// Styleguide 5.1.3

// カラー白 (Parts 5.1.4)
//
// Markup:
// <a class="m-btn m-btn--clW" href="">Parts 5.1.4</a>
//
// Styleguide 5.1.4

// 下矢印 (Parts 5.1.5)
//
// Markup:
// <a class="m-btn m-btn--anc" href="">Parts 5.1.5</a>
//
// Styleguide 5.1.5

// 汎用的なボタンを囲む要素 (Parts 5.2)
//
// ボタンが中央寄せに配置されます。
//
// Markup:
// <ul class="m-btnOuter">
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2</a>
//   </li>
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2</a>
//   </li>
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2</a>
//   </li>
// </ul>
//
// Styleguide 5.2

// 左寄せ (Parts 5.2.1)
//
// Markup:
// <ul class="m-btnOuter m-btnOuter--left">
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2.1</a>
//   </li>
// </ul>
//
// Styleguide 5.2.1

// ボタン間の余白が狭い (Parts 5.2.2)
//
// Markup:
// <ul class="m-btnOuter m-btnOuter--narrow">
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2.2</a>
//   </li>
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2.2</a>
//   </li>
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2.2</a>
//   </li>
// </ul>
//
// Styleguide 5.2.2

// 2カラム (Parts 5.2.3)
//
// Markup:
// <ul class="m-btnOuter m-btnOuter--2col">
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2.3</a>
//   </li>
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2.3</a>
//   </li>
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2.3</a>
//   </li>
//   <li class="m-btnOuter__item">
//     <a class="m-btn" href="">Parts 5.2.3</a>
//   </li>
// </ul>
//
// Styleguide 5.2.3

.m-btn {
  align-items: center;
  background-color: $color_primary;
  border: 1px solid $color_primary;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  @include fs(16);
  font-family: $font-family-primary;
  justify-content: center;
  line-height: 1;
  padding: 21px 40px;
  width: 300px;
  position: relative;
  cursor: pointer;
  @include sp {
    border-radius: 2px;
    @include vw(28);
    padding: 48/750*100vw 72/750*100vw;
    width: 100%;
  }
  &:after {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    height: 9px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transition: transform .2s ease-in;
    width: 9px;
    @include sp {
      border-width: 1px;
      height: 18/750*100vw;
      right: 32/750*100vw;
      width: 18/750*100vw;
    }
  }
  &:hover {
    color: #fff;
    &:after {
      @include pc {
        transform: translate(8px, -50%) rotate(45deg);
      }
    }
  }
  &--anc {
    &:after {
      transform: translate(0, calc(-50% - 2px)) rotate(135deg);
    }
    &:hover {
      &:after {
        @include pc {
          transform: translate(0, calc(-50% + 2px)) rotate(135deg);
        }
      }
    }
  }
  &--clW {
    background-color: #fff;
    border-color: $color_txt;
    color: $color_txt;
    &:hover {
      color: $color_primary;
    }
    &:after {
      border-color: $color_primary;
    }
  }
  &--s {
    width: 260px;
    @include sp() {
      padding: 36/750*100vw 0;
      width: 384/750*100vw;
    }
  }
  &--l {
    @include pc() {
      width: 420px;
    }
  }
  &--wide {
    width: 100%;
  }
  &--icnLeft {
    &:after {
      left: 20px;
      transform: translateY(-50%) rotate(225deg);
      @include sp {
        left: 32/750*100vw;
        width: 18/750*100vw;
      }
    }
    &:hover {
      &:after {
        @include pc {
          transform: translate(-8px, -50%) rotate(225deg);
        }
      }
    }
  }
}

.m-btnOuter {
  @include pc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -40px;
    margin-top: -40px;
  }
  &:not(:first-child) {
    margin-top: 20px;
    @include sp {
      margin-top: 60/750*100vw;
    }
  }
  &--narrow {
    @include pc {
      margin-left: -20px;
      margin-top: -20px;
    }
  }
  &--left {
    @include pc {
      justify-content: flex-start;
    }
  }
  &--2col {
    @include pc {
      padding-left: 160px;
      padding-right: 160px;
    }
  }
  &__item {
    @include pc {
      margin-left: 40px;
      margin-top: 40px;
    }
    &:not(:first-child) {
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
    .m-btnOuter--narrow & {
      @include pc {
        margin-left: 20px;
        margin-top: 20px;
      }
    }
  }
}