@charset "utf-8";

// 画像とテキスト
//
// Styleguide 12.0

// 画像とテキスト (Parts 12.1)
//
// Markup:
// <div class="m-imgTxt">
//   <div class="m-imgTxt__img">
//     <figure class="m-img">
//       <img src="https://placehold.jp/670x414.png" alt="Parts 12.1">
//     </figure>
//   </div>
//   <div class="m-imgTxt__contents">
//     <p class="m-txt">Parts 12.1</p>
//   </div>
// </div>
//
// Styleguide 12.1

.m-imgTxt {
  display: flex;
  @include sp {
    flex-direction: column;
  }
  &:not(:first-child) {
    margin-top: 16px;
    @include sp {
      margin-top: 24/750*100vw;
    }
    .m-ttlLv1 + & {
      margin-top: 40px;
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
  &__img {
    overflow: hidden;
    @include pc {
      flex: 0 0 auto;
      width: 400px;
    }
    @include sp {
      order: 1;
      margin-bottom: 40/750*100vw;
    }
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
      }
    }
    &--m {
      @include pc {
        flex: 0 0 auto;
        width: 480px;
      }
    }
    &--l {
      @include pc {
        flex: 0 0 auto;
        width: 530px;
      }
      &:not(:first-child) {
        @include sp {
          margin-bottom: 80/750*100vw;
        }
      }
    }
    &--spbottom {
      @include sp {
        order: 3;
        margin-bottom: 0;
        margin-top: 40/750*100vw;
      }
    }
  }
  &__contents {
    @include pc {
      flex: 1 1 auto;
    }
    @include sp {
      order: 2;
    }
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
      }
    }
  }
  &__thumb {
    overflow: hidden;
    @include pc {
      margin-top: 10px;
    }
    @include sp {
      margin-top: 16/750*100vw;
    }
  }
}