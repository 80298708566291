@charset "utf-8";

.p-guideDetail {
  &:not(:first-child) {
    @include pc {
      margin-top: 30px;
      .m-ttlBorderLv2 + & {
        margin-top: -30px;
      }
    }
    @include sp {
      margin-top: 30/750*100vw;
      .m-ttlBorderLv2 + & {
        margin-top: -30/750*100vw;
      }
    }
  }
  &__item {
    border-bottom: 1px solid $color_border;
    &:first-child {
      border-top: 1px solid $color_border;
      .m-ttlBorderLv2 + & {
        border-top: none;
      }
    }
  }
  &__contents {
    display: none;
    padding: 0 0 30px 0;
    position: relative;
    @include sp {
      padding: 0 0 40/750*100vw 0;
    }
  }
  &__ttl {
    align-items: center;
    cursor: pointer;
    display: flex;
    @include fs(24);
    font-weight: 700;
    line-height: 1.2;
    padding: 30px 60px 30px 0;
    position: relative;
    transition: color .2s ease-in;
    @include sp {
      @include vw(34);
      padding: 40/750*100vw 40/750*100vw 40/750*100vw 0;
    }
    &:hover {
      @include pc {
        color: $color_primary;
      }
    }
    &:before {
      content: "";
      display: block;
      border-top: solid 2px $color_primary;
      position: absolute;
      right: 20px;
      transform: translateY(-50%);
      top: 50%;
      width: 20px;
      z-index: 2;
      @include sp {
        border-width: 1px;
        right: 10/750*100vw;
        width: 32/750*100vw;
      }
    }
    &:after {
      content: "";
      display: block;
      border-top: solid 2px $color_primary;
      right: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      transition: transform .2s ease-in;
      width: 20px;
      z-index: 2;
      @include sp {
        content: "";
        border-width: 1px;
        right: 10/750*100vw;
        width: 32/750*100vw;
      }
    }
    &.is-open {
      &:after {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
  &__ttlIcn {
    display: block;
    flex: 0 0 auto;
    margin-right: 20px;
    @include sp {
      margin-right: 30/750*100vw;
    }
    img {
      width: 90px;
      @include sp {
        width: 90/750*100vw;
      }
    }
  }
  &__ttlTxt {
    display: block;
    flex: 1 1 auto;
  }
  &__ttlSub {
    color: $color_primary;
    display: inline-block;
    @include fs(16);
    @include sp {
      @include vw(20);
    }
  }
}

.p-guideBlock2 {
  @include pc {
    display: flex;
  }
  &__item {
    @include pc {
      width: calc((100% - 40px) / 2);
    }
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
      }
      @include sp {
        margin-top: 60/750*100vw;
      }
    }
    &--wide {
      @include pc {
        width: 52%;
      }
    }
  }
}

.p-guideCol2 {
  @include pc {
    display: flex;
  }
  &__item {
    @include pc {
      width: calc((100% - 40px) / 2);
    }
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
      }
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
}

.p-guideCol3 {
  @include pc {
    display: flex;
  }
  &__item {
    @include pc {
      width: calc((100% - 40px) / 3);
    }
    &:not(:first-child) {
      @include pc {
        margin-left: 40px;
      }
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
}

.p-guidePayment {
  display: flex;
  justify-content: center;
  &:not(:first-child) {
    margin-top: 26px;
    @include sp {
      margin-top: 50/750*100vw;
    }
  }
  &__item {
    text-align: center;
    width: calc((100% - 20px * 3 - .01px) / 4);
    @include sp {
      width: calc((100% - 4/750*100vw * 3 - .01px) / 4);
    }
    &:not(:first-child) {
      margin-left: 20px;
      @include sp {
        margin-left: 4/750*100vw;
      }
    }
  }
  &__img {
    img {
      width: 80px;
      @include sp {
        width: 130/750*100vw;
      }
    }
  }
  &__txt {
    @include fs(14);
    font-weight: 700;
    @include sp {
      @include vw(22);
    }
    &:not(:first-child) {
      margin-top: 8px;
      @include sp {
        margin-top: 12/750*100vw;
      }
    }
  }
}

.p-guideFlow {
  display: flex;
  flex-wrap: wrap;
  &:not(:first-child) {
    margin-top: 16px;
    @include sp {
      margin-top: 24/750*100vw;
    }
  }
  &__item {
    border: solid 1px $color_txt;
    border-radius: 4px;
    position: relative;
    &:before {
      .p-guideFlow--arrow & {
        @include pc {
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 14px solid $color_txt;
          content: "";
          display: block;
          left: -28px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    @include pc {
      margin-left: 40px;
      width: calc((100% - 40px * 3 - .01px) / 4);
    }
    @include sp {
      border-radius: 2px;
      margin-left: 40/750*100vw;
      width: calc((100% - (40/750*100vw)) / 2);
    }
    &:nth-child(4n + 1) {
      @include pc() {
        margin-left: 0;
        &:before {
          content: none;
        }
      }
    }
    &:nth-child(n + 5) {
      @include pc {
        margin-top: 40px;
      }
    }
    &:nth-child(2n + 1) {
      @include sp() {
        margin-left: 0;
      }
    }
    &:nth-child(n + 3) {
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
  &__ttl {
    align-items: center;
    background-color: $color_bg;
    border-radius: 4px 4px 0 0;
    display: flex;
    @include fs(16);
    font-weight: 700;
    height: 50px;
    justify-content: center;
    line-height: 1.25;
    text-align: center;
    @include sp {
      border-radius: 2px 2px 0 0;
      @include vw(22);
      height: 70/750*100vw;
    }
    &--l {
      display: inline-block;
      @include fs(30);
      margin-top: -6px;
      @include sp {
        @include vw(42);
        margin-top: -12/750*100vw;
      }
    }
  }
  &__contents {
    align-items: center;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    height: 150px;
    justify-content: center;
    padding: 0 20px;
    @include sp {
      border-radius: 0 0 2px 2px;
      height: 140/750*100vw;
      padding: 0 66/750*100vw;
    }
  }
  &__img {
    width: 100%;
    img {
      width: 100%;
    }
    &--delivery01 {
      @include pc {
        width: 134px;
      }
    }
  }
}