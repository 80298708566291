@charset "utf-8";

.m-sns {
  align-items: center;
  display: flex;
  @include sp {
    justify-content: center;
  }
  &__item {
    &:not(:first-child) {
      margin-left: 18px;
      @include sp {
        margin-left: 50/750*100vw;
      }
    }
    img {
      height: 24px;
      @include sp {
        height: 54/750*100vw;
      }
    }
  }
}