@charset "utf-8";

.p-faqList {
  &__item {
    border-bottom: 1px solid $color_border;
    &:first-child {
      border-top: 1px solid $color_border;
    }
  }
  &__contents {
    display: none;
    padding: 10px 80px 26px 80px;
    position: relative;
    @include sp {
      padding: 14/750*100vw 70/750*100vw 62/750*100vw 100/750*100vw;
    }
    &:before {
      align-items: center;
      background-color: $color_secondary;
      border-radius: 100vw;
      color: #fff;
      content: "A";
      display: flex;
      font-family: $font-family-primary;
      @include fs(20);
      font-weight: 700;
      height: 40px;
      justify-content: center;
      left: 20px;
      line-height: 1;
      position: absolute;
      top: 1px;
      width: 40px;
      @include sp {
        @include vw(38);
        height: 70/750*100vw;
        left: 0;
        width: 70/750*100vw;
      }
    }
  }
  &__ttlInner {
    display: block;
    &:before {
      align-items: center;
      background-color: $color_primary;
      border-radius: 100vw;
      color: #fff;
      content: "Q";
      display: flex;
      font-family: $font-family-primary;
      @include fs(20);
      font-weight: 700;
      height: 40px;
      justify-content: center;
      left: 20px;
      line-height: 1;
      position: absolute;
      top: 20px;
      width: 40px;
      @include sp {
        @include vw(38);
        height: 70/750*100vw;
        left: 0;
        top: 47/750*100vw;
        width: 70/750*100vw;
      }
    }
  }
  &__ttl {
    cursor: pointer;
    @include fs(16);
    line-height: 1.5;
    padding: 26px 80px 26px 80px;
    position: relative;
    transition: color .2s ease-in;
    @include sp {
      @include vw(28);
      padding: 62/750*100vw 70/750*100vw 62/750*100vw 100/750*100vw;
    }
    @include pc {
      color: $color_primary;
    }
    &:before {
      content: "";
      display: block;
      border-top: solid 2px $color_primary;
      position: absolute;
      right: 40px;
      transform: translateY(-50%);
      top: 50%;
      width: 20px;
      z-index: 2;
      @include sp {
        border-width: 1px;
        right: 40/750*100vw;
        width: 32/750*100vw;
      }
    }
    &:after {
      content: "";
      display: block;
      border-top: solid 2px $color_primary;
      right: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      transition: transform .2s ease-in;
      width: 20px;
      z-index: 2;
      @include sp {
        content: "";
        border-width: 1px;
        right: 40/750*100vw;
        width: 32/750*100vw;
      }
    }
    &.is-open {
      &:after {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
}