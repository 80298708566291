@charset "utf-8";
.l-footer {
  position: relative;
}

.l-footerMain {
  background-color: $color_bg;
  color: #fff;
  padding: 60px 0;
  @include sp {
    padding: 100/750*100vw 0 250/750*100vw 0;
    position: relative;
  }
  &__inner {
    @include pc {
      display: flex;
      justify-content: space-between;
    }
  }
}

.l-footerMainLogo {
  margin-bottom: 40;
  @include sp {
    margin-bottom: 108/750*100vw;
    text-align: center;
  }
  img {
    @include pc {
      width: 282px;
    }
    @include sp {
      width: 450 /750*100vw;
    }
  }
}

.l-footerMainSns {
  @include pc {
    margin-top: 40px;
  }
  @include sp {
    bottom: 100/750*100vw;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.l-footerLink {
  display: flex;
  flex-wrap: wrap;
  &__item {
    @include fs(14);
    font-weight: 700;
    @include sp {
      @include vw(24);
    } 
    &:not(:first-child) {
      @include pc {
        margin-left: 30px;
      }
      @include sp {
        margin-top: 80/750*100vw;
      }
    }
  }
  &__link {
    color: $color_txt;
  }
}

.l-footerLinkLv2 {
  margin-top: 10px;
  @include pc {
    padding-left: 10px;
  }
  @include sp {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40/750*100vw;
  }
  &__item {
    font-weight: 400;
    @include sp {
      margin-left: 120/750*100vw;
      width: calc((100% - 120/750*100vw) / 2);
    }
    &:not(:first-child) {
      @include pc {
        margin-top: 10px;
      }
    }
    &:nth-child(2n + 1) {
      @include sp() {
        margin-left: 0;
      }
    }
    &:nth-child(n + 3) {
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
}

.l-footerOutline {
  background-color: $color_primary;
  color: #fff;
  padding: 30px 0;
  position: relative;
  @include sp {
    padding: 44/750*100vw 0;
  }
  &__inner {
    @include pc {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
  &__link {
    align-items: center;
    display: flex;
    @include sp {
      justify-content: center;
      margin-bottom: 20/750*100vw;
    }
    li {
      @include fs(14);
      @include sp {
        @include vw(24);
      }
      &:not(:first-child) {
        margin-left: 30px;
        @include sp {
          margin-left: 30/750*100vw;
        }
      }
    }
    a {
      color: #fff;
    }
  }
  &__copyright {
    @include fs(14);
    letter-spacing: 0;
    @include sp {
      @include vw(24);
      text-align: center;
    }
  }
}