@charset "utf-8";
.l-pageTop {
  height: 80px;
  opacity: 0;
  position: fixed;
  right: 0;
  transition: opacity .4s ease-in;
  width: 80px;
  z-index: 99;
  @include sp {
    // height: 130/750*100vw;
    height: 100/750*100vw;
    width: 130/750*100vw;
  }
  &.is-fixed {
    bottom: 0;
    opacity: 1;
  }
  &.is-absolute {
    bottom: 0;
    position: fixed;
    opacity: 1;
    // position: absolute;
    // top: -80px;
    @include sp {
      // top: -130/750*100vw;
    }
  }
  &__btn {
    align-items: center;
    display: flex;
    flex-direction: column;
    @include fs(16);
    font-family: $font-family-primary;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;
    @include sp {
      @include vw(34);
    }
    .is-absolute & {
      color: #fff;
    }
    &:before {
      border-left: 2px solid $color_primary;
      border-top: 2px solid $color_primary;
      content: "";
      display: inline-block;
      height: 16px;
      width: 16px;
      transform: translateY(25%) rotate(45deg);
      transition: transform .3s ease-out;
      @include sp {
        height: 32/750*100vw;
        width: 32/750*100vw;
      }
      .is-absolute & {
        border-color: #fff;
      }
    }
    &:hover {
      opacity: 1;
      &:before {
        @include pc {
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }
}

body #chamo-window {
  z-index: 99;
}

body #chamo-window #chamo-waiting,
body #chamo-window #chamo-offline-waiting {
  bottom: 85px !important;
  right: 0 !important;
  @include sp {
    bottom: 100/750*100vw !important;
    right: -10/750*100vw !important;
  }
}