@charset "utf-8";
.m-select {
  position: relative;
	overflow: hidden;
  &:before {
    border-bottom: 1px solid $color_txt;
    border-right: 1px solid $color_txt;
    content: "";
    display: inline-block;
    height: 11px;
    width: 11px;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 2;
    @include sp {
      height: 22/750*100vw;
      right: 46/750*100vw;
      width: 22/750*100vw;
    }
  }
  select {
    appearance: none;
    box-shadow: none;
    color: $color_txt;
    padding-right: 40px;
    @include sp {
      padding-right: 80/750*100vw;
    }
    &::-ms-expand {
      display: none;
    }
  }
}
