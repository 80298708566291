@charset "utf-8";
.m-radio {
  display: inline-block;
  @include fs(16);
  @include sp {
    @include vw(28);
  }
  input[type=radio] {
    display: none;
  }
  &__txt {
    cursor: pointer;
    display: inline-block;
    padding-left: 24px;
    position: relative;
    &:before {
      background: #fff;
      border: 1px solid $color_borderEnclosure;
      border-radius: 50%;
      content: '';
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      top: 5px;
      width: 20px;
      .is-error & {
        background: $color_attentionBg;
      }
    }
    &:after {
      background: $color_txt;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      left: 5px;
      opacity: 0;
      position: absolute;
      top: 10px;
      width: 10px;
    }
  }
}


input[type=radio]:checked + .m-radio__txt::after {
  opacity: 1;
}