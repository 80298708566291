@charset "utf-8";

// 表組
//
// Styleguide 9.0

// 通常 (Parts 9.1)
//
// 見出しセルには'cell--ttl'を付与でスタイルがつく。<br>
// セルはデフォルト左寄せ。'cell--center'付与で左寄せになる。
//
// Markup:
// <div class="m-tbl">
//   <table>
//     <thead>
//       <tr>
//         <th class="cell--ttl">Parts 9.1</th>
//         <th class="cell--ttl">Parts 9.1</th>
//         <th class="cell--ttl">Parts 9.1</th>
//         <th class="cell--ttl">Parts 9.1</th>
//         <th class="cell--ttl">Parts 9.1</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <td>Parts 9.1</td>
//         <td>Parts 9.1</td>
//         <td>Parts 9.1</td>
//         <td>Parts 9.1</td>
//         <td>Parts 9.1</td>
//       </tr>
//       <tr>
//         <td class="cell--center">Parts 9.1</td>
//         <td class="cell--center">Parts 9.1</td>
//         <td class="cell--center">Parts 9.1</td>
//         <td class="cell--center">Parts 9.1</td>
//         <td class="cell--center">Parts 9.1</td>
//       </tr>
//     </tbody>
//   </table>
// </div>
//
// Styleguide 9.1

// 背景色が交互 (Parts 9.2)
//
// Markup:
// <div class="m-tbl m-tbl--alternate">
//   <table>
//     <thead>
//       <tr>
//         <th class="cell--ttl">Parts 9.2</th>
//         <th class="cell--ttl">Parts 9.2</th>
//         <th class="cell--ttl">Parts 9.2</th>
//         <th class="cell--ttl">Parts 9.2</th>
//         <th class="cell--ttl">Parts 9.2</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <td>Parts 9.2</td>
//         <td>Parts 9.2</td>
//         <td>Parts 9.2</td>
//         <td>Parts 9.2</td>
//         <td>Parts 9.2</td>
//       </tr>
//       <tr>
//         <td>Parts 9.2</td>
//         <td>Parts 9.2</td>
//         <td>Parts 9.2</td>
//         <td>Parts 9.2</td>
//         <td>Parts 9.2</td>
//       </tr>
//     </tbody>
//   </table>
// </div>
//
// Styleguide 9.2

// 幅auto (Parts 9.3)
//
// Markup:
// <div class="m-tbl m-tbl--auto">
//   <table>
//     <thead>
//       <tr>
//         <th class="cell--ttl">Parts 9.3</th>
//         <th class="cell--ttl">Parts 9.3</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <td>Parts 9.3</td>
//         <td>Parts 9.3</td>
//       </tr>
//       <tr>
//         <td>Parts 9.3</td>
//         <td>Parts 9.3</td>
//       </tr>
//     </tbody>
//   </table>
// </div>
//
// Styleguide 9.3

// スクロールする表組 (Parts 9.4)
//
// 'm-tbl--scroll'付与。'data-simplebar''data-simplebar-auto-hide="false"'付与。
//
// Markup:
// <div class="m-tbl m-tbl--scroll" data-simplebar data-simplebar-auto-hide="false">
//   <table>
//     <thead>
//       <tr>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//         <th class="cell--ttl">Parts 9.4</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//       </tr>
//       <tr>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//         <td>Parts 9.4</td>
//       </tr>
//     </tbody>
//   </table>
// </div>
//
// Styleguide 9.4

.m-tbl {
  width: 100%;
  &.m-tbl--scroll {
    overflow: auto;
    padding-bottom: 24px;
    white-space: nowrap;
    @include sp {
      padding-bottom: 20px;
    }
  }
  &.m-tbl--alternate {
    tr {
      &:nth-child(2n) {
        th,
        td {
          background-color: $color_bg;
        }
      }
    }
  }
  &.m-tbl--auto {
    table {
      width: auto;
    }
  }
  table {
    width: 100%;
  }
  th,
  td {
    background-color: #fff;
    border: 1px solid $color_borderEnclosure;
    @include fs(16);
    font-weight: 400;
    text-align: left;
    padding: 6px 16px;
    vertical-align: middle;
    @include sp {
      @include vw(28);
      padding: 16/750*100vw 16/750*100vw;
    }
    &.cell {
      &--ttl {
        background-color: #888;
        color: #fff;
        text-align: center;
      }
      &--center {
        text-align: center;
      }
      &--fixedLeft {
        position: sticky;
        left: 0;
        z-index: 1;
        &:before{
          border-left: 1px solid $color_borderEnclosure;
          content: "";
          height: 100%;
          left: -1px;
          position: absolute;
          top: -1px;
          width: 100%;
        }
      }
    }
  }
}