@charset "utf-8";

.m-wordSearchRadio {
  display: flex;
  &__item {
    &:not(:first-child) {
      margin-left: 20px;
      @include sp {
        margin-left: 40/750*100vw;
      }
    }
  }
}

.m-wordSearchInput {
  display: flex;
  height: 54px;
  margin-top: 16px;
  @include sp {
    height: 120/750*100vw;
    margin-top: 30/750*100vw;
  }
  &__input[type=text] {
    border-right: 0;
    border-radius: 4px 0 0 4px;
    flex: 1 1 auto;
    height: inherit;
    @include sp {
      padding: 14px 18px;
    }
    @include sp {
      border-radius: 2px 0 0 2px;
    }
  }
  &__btn {
    align-items: center;
    display: flex;
    background: $color_primary;
    border-radius: 0 4px 4px 0;
    flex: 0 0 auto;
    height: inherit;
    justify-content: center;
    width: 54px;
    @include sp {
      border-radius: 0 2px 2px 0;
      width: 120/750*100vw;
    }
    img {
      width: 20px;
      @include sp {
        width: 44/750*100vw;
      }
    }
  }
}