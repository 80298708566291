@charset "utf-8";

.m-card {
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin-left: 20px;
    width: calc((100% - 20px * 3 - .01px) / 4);
    @include sp {
      margin-left: 40/750*100vw;
      width: calc((100% - 40/750*100vw - .01px) / 2);
    }
    .m-card--large & {
      @include pc() {
        margin-left: 40px;
        width: calc((100% - 40px * 3 - .01px) / 4);
      }
    }
    &:nth-child(4n + 1) {
      @include pc() {
        margin-left: 0;
      }
    }
    &:nth-child(2n + 1) {
      @include sp() {
        margin-left: 0;
      }
    }
    &:nth-child(n + 5) {
      @include pc {
        margin-top: 40px;
      }
    }
    &:nth-child(n + 3) {
      @include sp {
        margin-top: 40/750*100vw;
      }
    }
  }
  &__img {
    margin-bottom: 10px;
    width: 100%;
    @include sp {
      @include vw(28);
      margin-bottom: 20/750*100vw;
    }
    img {
      border-radius: 4px;
      height: auto;
      @include sp {
        border-radius: 2px;
      }
    }
  }
  &__id {
    @include fs(18);
    line-height: 1.5;
    font-weight: 700;
    @include sp {
      @include vw(28);
    }
    &:not(:last-child) {
      margin-bottom: 10px;
      @include sp {
        margin-bottom: 20/750*100vw;
      }
    }
  }
  &__ttl {
    @include fs(16);
    line-height: 1.5;
    @include sp {
      @include vw(28);
    }
    &:not(:last-child) {
      margin-bottom: 10px;
      @include sp {
        margin-bottom: 20/750*100vw;
      }
    }
  }
  &__scene {
    @include fs(13);
    @include sp {
      @include vw(24);
    }
    &:not(:last-child) {
      margin-bottom: 15px;
      @include sp {
        margin-bottom: 30/750*100vw;
      }
    }
    &.m-card__scene--one {
      dd {
        &:after {
          content: none;
        }
      }
    }
    dt {
      line-height: 1.5;
    }
    dd {
      line-height: 1;
      margin-top: 6px;
      padding-right: 20px;
      position: relative;
      @include sp {
        margin-top: 10/750*100vw;
        padding-right: 24/750*100vw;
      }
      &:after {
        bottom: 6px;
        content: "他";
        display: inline-block;
        position: absolute;
        right: 0;
        @include sp {
          bottom: 12/750*100vw;
        }
      }
      a {
        background-color: $color_bg;
        border-radius: 100vw;
        display: inline-block;
        line-height: 1;
        padding: 6px 16px;
        text-align: center;
        @include pc {
          min-width: 66px;
        }
        @include sp {
          padding: 13/750*100vw 36/750*100vw;
        }
      }
    }
  }
  &__attribute {
    @include fs(13);
    @include sp {
      @include vw(24);
    }
    &:not(:last-child) {
      margin-bottom: 15px;
      @include sp {
        margin-bottom: 30/750*100vw;
      }
    }
    dt {
      line-height: 1.5;
    }
    dd {
      line-height: 1;
      margin-left: -6px;
      display: flex;
      flex-wrap: wrap;
      @include sp {
        margin-left: -10/750*100vw;
      }
      a {
        background-color: $color_bg;
        border-radius: 100vw;
        display: inline-block;
        line-height: 1;
        margin-left: 6px;
        margin-top: 6px;
        padding: 6px 16px;
        text-align: center;
        @include pc {
          min-width: 66px;
        }
        @include sp {
          margin-left: 10/750*100vw;
          margin-top: 10/750*100vw;
          padding: 13/750*100vw 36/750*100vw;
        }
      }
    }
  }
}