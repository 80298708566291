@charset "utf-8";

.l-main {
  background-color: #fff;
  @include pc {
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    position: relative;
  }
  @include sp {
    padding-top: 110/750*100vw;
  }
}
