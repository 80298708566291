@charset "utf-8";

// テキストリンク
//
// Styleguide 8.0

// 通常テキストリンク (Parts 8.1)
//
// Markup:
// <a class="m-linkTxt" href="">Parts 8.1</a>
//
// Styleguide 8.1

// アイコンあり (Parts 8.2)
//
// Markup:
// <a class="m-linkTxt m-linkTxt--external" href="">Parts 8.2</a>
//
// Styleguide 8.2

@mixin icn($name) {
  &--#{$name} {
    &:after {
      background-image: url(#{$imgPath}common/icn_#{$name}.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: inline-block;
      height: 0.9em;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: -2px;
      vertical-align: middle;
      width: 0.9em;
    }
  }
}

.m-linkTxt {
  cursor: pointer;
  color: $color_primary;
  text-decoration: underline;
  @include icn(external);
  @include icn(pdf);
  &:hover {
    text-decoration: none;
  }
}