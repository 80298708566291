@charset "utf-8";

.m-detailList {
  display: flex;
  flex-wrap: wrap;
  &__item {
    border-bottom: 1px solid $color_borderEnclosure;
    border-right: 1px solid $color_borderEnclosure;
    border-top: 1px solid $color_borderEnclosure;
    text-align: center;
    @include pc {
      padding: 10px;
      width: calc(100% / 4);
    }
    @include sp {
      padding: 10px;
      width: calc(100% / 2);
    }
    &:nth-child(4n + 1) {
      @include pc() {
        border-left: 1px solid $color_borderEnclosure;
      }
    }
    &:nth-child(2n + 1) {
      @include sp() {
        border-left: 1px solid $color_borderEnclosure;
      }
    }
    &:nth-child(n + 5) {
      @include pc {
        border-top: 0;
      }
    }
    &:nth-child(n + 3) {
      @include sp {
        border-top: 0;
      }
    }
  }
}

